import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import CanvasGrid from "../components/canvas-grid"
import HomeHero from "../components/home-hero"
import Cards from "../components/cards"
import Button from "../components/button"
import Carousel from "../components/carousel"
import AppTicker from "../components/appticker"
import { useTranslation } from "gatsby-plugin-react-i18next-nocookie"
import Icon from "@mdi/react"
import { mdiEmailOutline } from "@mdi/js"

import "../styles/pages/index.scss"

const IndexPage = ({ data }) => {
  const { t } = useTranslation(["translation", "index"])

  return (
    <Layout>
      <SEO
        title={t("index:metadata.title")}
        description={t("index:metadata.desc")}
        image="theloft.jpg"
      />
      <CanvasGrid class="canvas-grid--dark" theme="dark" />
      <HomeHero />
      <div className="container-fluid sp-pt-9 sp-pb-9">
        <div className="row text-center">
          <div className="col-md-10 m-auto">
            <div className="title text--sm sp-mb-4">
              {t("index:Stylish_Elegant_Versatile")}
            </div>
            <div className="content text--lg sp-mb-7">
              {t("index:Stylish_Elegant_Versatile_Desc")}
            </div>
            <div className="content text--sm">
              <Button to="/book" className="button button--dark button--icon">
                {t("translation:book")}
              </Button>
            </div>
          </div>
        </div>
      </div>

      <Cards cards={data.cards.edges} />

      <div className="container-fluid">
        <div className="row text-center">
          <div className="col-md-10 m-auto">
            <div className="title text--sm sp-mb-4">{t("index:faq.title")}</div>
            <div className="content text--lg sp-mb-7">
              {t("index:faq.content")}
            </div>
            <Button
              className="button button--dark button--icon sp-ml-2 sp-mr-2 sp-mb-4"
              to={`/faq`}
            >
              {t("index:faq.button")}
            </Button>
            <a
              href="mailto:hello@thehighloft.com"
              className="button sp-ml-2 sp-mr-2 sp-mb-4 button--dark button--icon"
            >
              hello@thehighloft.com
              <span className="icon">
                <Icon path={mdiEmailOutline} />
              </span>
            </a>
          </div>
        </div>
      </div>

      <Carousel
        className="sp-pt-9"
        images={data.carouselImages.nodes}
        slidesPerView={3.5}
        spaceBetween={75}
        breakpoints={{
          320: {
            slidesPerView: 1.5,
            spaceBetween: 20,
          },
          992: {
            slidesPerView: 1.9,
            spaceBetween: 75,
          },
        }}
      />

      <AppTicker />
    </Layout>
  )
}

export const pageQuery = graphql`
  query PageQuery($language: String) {
    cards: allMarkdownRemark(
      filter: {
        frontmatter: { lang: { eq: $language } }
        fileAbsolutePath: { regex: "/home/cards/" }
      }
      sort: { order: ASC, fields: fileAbsolutePath }
    ) {
      edges {
        node {
          frontmatter {
            title
            buttons {
              button
              link
            }
          }
          html
        }
      }
    }

    carouselImages: allFile(
      filter: { relativePath: { regex: "/home/carousel/" } }
    ) {
      nodes {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`

export default IndexPage
