import React, { useEffect, useRef } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import Logo from "../static/logo.svg"
import { useWindowSize } from "../hooks/windowsize"
import { gsap } from "gsap"

const HomeHero = () => {
  const gridRef = useRef(null)

  const data = useStaticQuery(graphql`
    query ImagesQuery {
      allFile(filter: { relativePath: { regex: "/^homehero/.*?/" } }) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)

  const { nodes } = data.allFile

  return (
    <div className="home-hero vh-100">
      <div className="home-hero__container" ref={gridRef}>
        <div className="home-hero__logo">
          <Logo />
        </div>
        {nodes.map((img, i) => {
          return <GridItem key={i} img={img} number={i} />
        })}
      </div>
    </div>
  )
}

const GridItem = props => {
  const size = useWindowSize()

  const ref = useRef(null)

  // Mouse / Gyroscope move
  useEffect(() => {
    // Detect gyroscope
    let hasGyroscope = false
    if (window.DeviceOrientationEvent && "ontouchstart" in window) {
      hasGyroscope = true
    }

    // Gets the mouse position
    const getMousePos = e => {
      let posx = 0
      let posy = 0
      if (!e) e = window.event
      if (e.pageX || e.pageY) {
        posx = e.pageX
        posy = e.pageY
      } else if (e.clientX || e.clientY) {
        posx =
          e.clientX +
          document.body.scrollLeft +
          document.documentElement.scrollLeft
        posy =
          e.clientY +
          document.body.scrollTop +
          document.documentElement.scrollTop
      }

      return { x: posx, y: posy }
    }

    // Gets orientation position
    const handleOrientation = e => {
      return {
        x: ((e.gamma + 90) * Math.PI) / 2,
        y: (e.beta + 90) * Math.PI,
      }
    }

    // initial mouse position
    let mousepos = { x: size.width / 2, y: size.height / 2 }

    // Moves handler
    const mousemove = e => {
      const { top, height } = ref.current.getBoundingClientRect()
      if (top + height < 0) {
        return
      }

      mousepos = hasGyroscope ? handleOrientation(e) : getMousePos(e)
    }

    // Raf, Runs on every tick
    const xstart = gsap.utils.random([40, 80])
    const ystart = gsap.utils.random([40, 80])
    const move = () => {
      gsap.to(ref.current, {
        x: gsap.utils.mapRange(0, size.width, -xstart, xstart, mousepos.x),
        y: gsap.utils.mapRange(0, size.height, -ystart, ystart, mousepos.y),
      })
    }
    gsap.ticker.add(move)

    //Add listeners
    if (hasGyroscope) {
      window.addEventListener("deviceorientation", mousemove)
    } else {
      window.addEventListener("mousemove", mousemove)
    }

    return () => {
      // Remove listeners
      if (hasGyroscope) {
        window.removeEventListener("deviceorientation", mousemove)
      } else {
        window.removeEventListener("mousemove", mousemove)
      }
      // Remove raf
      gsap.ticker.remove(move)
    }
  }, [size])

  // Fadein & scale to 1 on load
  useEffect(() => {
    const tl = gsap
      .timeline()
      .set(ref.current, { scale: pos => 0.6, opacity: 0 }, 0)
      .to(
        ref.current,
        {
          duration: 2,
          ease: "Expo.easeOut",
          scale: 1,
          stagger: { amount: 0.6, from: "center" },
        },
        0
      )
      .to(
        ref.current,
        {
          duration: 2,
          ease: "Power1.easeOut",
          opacity: 1,
          stagger: { amount: 0.6, from: "center" },
        },
        0
      )
    return () => {
      tl.kill()
    }
  }, [])

  return (
    <div className={`home-hero__child home-hero__child--${props.number + 1}`}>
      <div ref={ref}>
        <Img
          className="hero-follow-mouse"
          draggable={false}
          fluid={props.img.childImageSharp.fluid}
        />
      </div>
    </div>
  )
}

export default HomeHero
